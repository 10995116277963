import { whenLoginStateResolved } from '../../utils/loginState';
import thirdPartyCookie from '../../autologin/thirdPartyCookie';
import { getAidDomain, isOnTestSite } from '../../utils/url';
import log from '../../utils/log';
import { OVERRIDE_REFRESH_BLOCK, refreshData } from '../../actions/refreshData';

export default async function fedCMHandler() {
  if (!browserSupportsFedCM()) return;
  if (!isOnTestSite()) return;
  if (await whenLoginStateResolved()) return;
  if (await thirdPartyCookie()) return;

  if (await attemptFedCMAuthentication()) {
    await refreshData(OVERRIDE_REFRESH_BLOCK);
  }
}

function browserSupportsFedCM(): boolean {
  return 'IdentityCredential' in window;
}

async function attemptFedCMAuthentication(): Promise<boolean> {
  try {
    const credential = await navigator.credentials.get({
      // Such ignore-mode. FecCM not part of spec yet
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      identity: {
        context: 'signin',
        providers: [
          {
            configURL: `https://${getAidDomain()}/api/aid/fedcm/config`,
            clientId: 'amedia',
            nonce: crypto.randomUUID(),
          },
        ],
      },
    });

    const response = await fetch('/api/aid/fedcm/finalize', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // Such ignore-mode. FecCM not part of spec yet
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        token: credential.token,
      }),
    });
    if (!response.ok) {
      console.error('Failed to finalize session in FedCM flow', response);
      log.error('Failed to finalize session in FedCM flow', response);
      return false;
    }
    return true;
  } catch (error) {
    console.error('FedCM authentication failed', error);
    log.error('FedCM failed', error);
    return false;
  }
}
