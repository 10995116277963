import { dispatch, listenWithExclusivity } from '../utils/customEventWrapper';
import log from '../utils/log';
import getGlobalThis from '../utils/getGlobalThis';

import { removeUserStorage } from './jobs/removeUserStorage';
import accessCookieRefresher from './jobs/accessCookieRefresher';
import fedCMHandler from './jobs/fedCM';

const jobs: Array<() => Promise<void>> = [
  accessCookieRefresher,
  removeUserStorage,
  fedCMHandler,
];

async function performJobs() {
  if (typeof window === 'undefined') {
    // None of our current jobs make any sense to run in a web worker or other settings without a window object.
    return;
  }
  if (getGlobalThis()?.location?.hostname === 'localhost') {
    // Neither if we're running on localhost as this will never be able to resolve any data

    log.warn(
      '@amedia/user started on localhost. In this scenario we have no way to resolve any login-, access- or userdata. Expect errors and/or redirects if you try.'
    );
    return;
  }

  for (const job of jobs) {
    await job();
  }
}

enum JanitorEvents {
  LOADED = 'amedia-user.janitor.loaded',
}

declare global {
  interface WindowEventMap {
    [JanitorEvents.LOADED]: CustomEvent<Record<string, unknown>>;
  }
}

// We want to ensure that the Janitor is started exactly once. The trick here is to
// listen to an event and if we receive it exclusively, we know that we are the first
// to load this script and therefore responsible for running the Janitor once.
export const runJanitor = async () => {
  let janitorStarted = false;
  listenWithExclusivity(JanitorEvents.LOADED, () => {
    if (janitorStarted) {
      return;
    }

    janitorStarted = true;
    performJobs();
  });
  dispatch(new CustomEvent(JanitorEvents.LOADED));
};
